import * as Yup from "yup"

export const FIELDS_NAME = {
   DOWN_PAYMENT_PERCENTAGE: "percentage",
   STATUS: "status",
}

export const initialValues = {
   [FIELDS_NAME.DOWN_PAYMENT_PERCENTAGE]: "",
   [FIELDS_NAME.STATUS]: "ACTIVE",
}

export const validationSchema = Yup.object({
   [FIELDS_NAME.DOWN_PAYMENT_PERCENTAGE]: Yup.number("Enter numbers only")
      .required("Enter percentage")
      .test(
         "is-valid-percentage",
         "Percentage must be between 1 and 100, and can include decimals (e.g., 8.55)",
         value => {
            if (!value) return false
            const num = parseFloat(value)
            const isInteger = Number.isInteger(num)

            return (
               (isInteger && num >= 1 && num <= 100) ||
               (!isInteger && num > 0 && num <= 100)
            )
         }
      ),

   [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
})
