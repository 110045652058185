import React, { useState, useEffect } from "react"
import { CardBody, Col, Row, FormGroup, Label } from "reactstrap"
import * as Yup from "yup"

import { Button } from "components/Button"
import { TextInput } from "components/TextInput"
import { useFormik } from "formik"
import { GetUserListing, WarehouseListing } from "api/api.service"
import { AutoCompleteStyling } from "../../../Common/AutoCompleteStyling"
import { AutoComplete } from "components/AutoComplete"
import Config from "Config"
import { UserRoles } from "constants"

const AddEditDepartment = ({
   btnText = "",
   data = null,
   isLoading = false,
   onSubmitForm = () => {},
}) => {
   let controller = new AbortController()
   let signal = controller.signal

   const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
         name: data?.departmentName,
         address: data?.address,
         location: data?.location,
         user: data?.departmentAssignments.length
            ? {
                 label: data?.departmentAssignments[0]?.user.firstName,
                 value: data?.departmentAssignments[0]?.user.id,
              }
            : null,

         warehouse: data?.departmentAssignments?.length
            ? {
                 label: data?.departmentAssignments[0]?.department.warehouse
                    ?.warehouseName,
                 value: data?.departmentAssignments[0]?.department.warehouse
                    ?.id,
              }
            : null,
      },
      validationSchema: Yup.object({
         name: Yup.string()
            .required("Enter stage name")
            .min(1, "Station name should be minimum 1 characters long")
            .matches(/^[^/]*$/, "Name cannot contain '/'"),
         address: Yup.string()
            .nullable()
            .min(1, "Address should be minimum 6 characters long"),
         location: Yup.string()
            .nullable()
            .min(1, "Location should be minimum 6 characters long"),
         user: Yup.object()
            .nullable()
            .required("Department manager is required"),
         warehouse: Yup.object().nullable().required("Warehouse is required"),
      }),
      onSubmit: values => {
         onSubmitForm(values)
      },
   })

   const [userListing, setUserListing] = useState({
      data: [],
      fetching: true,
      page: 1,
      Q: "",
      isDataAvailable: false,
   })

   const [warehouseListing, setWarehouseListing] = useState({
      data: [],
      fetching: true,
      page: 1,
      Q: "",
      isDataAvailable: false,
   })

   useEffect(() => {
      getUserListingData(userListing.page)

      return () => {
         controller.abort()
      }
   }, [userListing.Q])

   useEffect(() => {
      getWarehouseListing(warehouseListing.page)

      return () => {
         controller.abort()
      }
   }, [warehouseListing.Q])

   const handleSearch = (value, callback) => {
      if (!value) return

      callback(prev => {
         return {
            ...prev,
            data: [],
            page: 1,
            Q: value,
         }
      })
   }

   const getUserListingData = page => {
      handleStateSet(setUserListing, { fetching: true, data: [] })
      GetUserListing(
         {
            limit: Config.LIMIT,
            ...(userListing.Q && { Q: userListing.Q }),
            page: page,
            type: UserRoles.Department,
            status: "ACTIVE",
            // assigning: "notAssined",
         },
         signal
      )
         .then(res => {
            setUserListing(prev => ({
               ...prev,
               fetching: false,
               page: page + 1,
               isDataAvailable: res?.data.length === Config.LIMIT,
               data: (() => {
                  let test = res?.data.map(item => ({
                     ...item,
                     value: item.id,
                     label: `${item?.firstName} ${item?.lastName}`,
                  }))
                  return [...test, ...prev.data]
               })(),
            }))
         })
         .finally(() => {
            handleStateSet(setUserListing, { fetching: false })
         })
   }

   const handleStateSet = (callback = () => {}, keysObj = {}) => {
      callback(prev => {
         return {
            ...prev,
            ...(keysObj && keysObj),
         }
      })
   }

   const handleSelectedUser = selectedUser => {
      formik.setFieldValue("user", selectedUser)
   }
   const getWarehouseListing = page => {
      handleStateSet(setWarehouseListing, { fetching: true, data: [] })
      WarehouseListing(
         {
            limit: Config.LIMIT,
            ...(warehouseListing.Q && { Q: warehouseListing.Q }),
            page: page,
         },
         signal
      )
         .then(res => {
            setWarehouseListing(prev => ({
               ...prev,
               fetching: false,
               page: page + 1,
               isDataAvailable: res?.data?.length === Config.LIMIT,
               data: (() => {
                  let test = res?.data?.map(item => ({
                     ...item,
                     value: item.id,
                     label: item.warehouseName,
                  }))
                  return [...test, ...prev.data]
               })(),
            }))
         })
         .finally(() => {
            handleStateSet(setWarehouseListing, { fetching: false })
         })
   }
   const handleSelectedWarehouse = selectedWarehouse => {
      formik.setFieldValue("warehouse", selectedWarehouse)
   }
   return (
      <div className="d-flex flex-column align-items-center mb-4">
         <CardBody className="location-add-edit-card-container shadow-none">
            <form onSubmit={formik.handleSubmit}>
               <div className="location-form-handler">
                  <FormGroup className="d-flex location-add-edit-fg">
                     <Label
                        for="full name"
                        className="location-modal-label "
                        sm={3}
                     >
                        Name
                     </Label>
                     <Col sm={8} className="input-text-container ">
                        <TextInput
                           className="location-modal-input"
                           type="text"
                           name="name"
                           placeholder="Type here"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={formik.values.name}
                        />
                     </Col>
                  </FormGroup>
                  <Row className="mb-3">
                     <Col sm={3}></Col>
                     <Col sm={8}>
                        {formik.touched.name && formik.errors.name ? (
                           <span className="error fs-10">
                              {formik.errors.name}
                           </span>
                        ) : null}
                     </Col>
                  </Row>
                  <FormGroup className="d-flex mb-0 location-add-edit-fg">
                     <Label
                        for="address"
                        className="location-modal-label "
                        sm={3}
                     >
                        Address
                     </Label>
                     <Col sm={8} className="input-text-container ">
                        <TextInput
                           className="location-modal-input "
                           type="text"
                           name="address"
                           placeholder="Type here"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={formik.values.address}
                        />
                     </Col>
                  </FormGroup>
                  <Row className="mb-3">
                     <Col sm={3}></Col>
                     <Col sm={8}>
                        {formik.touched.address && formik.errors.address ? (
                           <span className="error fs-10">
                              {formik.errors.address}
                           </span>
                        ) : null}
                     </Col>
                  </Row>
                  <FormGroup className="d-flex mb-0 location-add-edit-fg">
                     <Label
                        for="location"
                        className="location-modal-label"
                        sm={3}
                     >
                        Location
                     </Label>
                     <Col sm={8} className="input-text-container ">
                        <TextInput
                           className="location-modal-input"
                           type="text"
                           name="location"
                           placeholder="Type here"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={formik.values.location}
                        />
                     </Col>
                  </FormGroup>
                  <Row className="mb-3">
                     <Col sm={3} lg={3} md={3}>
                        {" "}
                     </Col>
                     <Col sm={8}>
                        {formik.touched.location && formik.errors.location ? (
                           <span className="error fs-10">
                              {formik.errors.location}
                           </span>
                        ) : null}
                     </Col>
                  </Row>

                  <>
                     <FormGroup className="d-flex mb-0 location-add-edit-fg w-100">
                        <Label
                           for="location"
                           className="location-modal-label"
                           sm={3}
                        >
                           Department Manager
                        </Label>
                        <Col sm={8} className="input-text-container ">
                           <AutoComplete
                              handleBlur={() =>
                                 setUserListing(prev => {
                                    return {
                                       ...prev,
                                       page: 1,
                                       Q: "",
                                    }
                                 })
                              }
                              onChange={handleSelectedUser}
                              onInputChange={val =>
                                 handleSearch(val, setUserListing)
                              }
                              placeholder="Select department manager"
                              classNamePrefix="add-new-req"
                              customStyles={AutoCompleteStyling}
                              options={userListing?.data}
                              value={formik.values.user}
                              isLoading={userListing?.fetching}
                           />
                        </Col>
                     </FormGroup>
                     <Row className="mb-3">
                        <Col sm={3} lg={3} md={3}>
                           {" "}
                        </Col>
                        <Col sm={8}>
                           {formik.touched.user && formik.errors.user ? (
                              <span className="error fs-10">
                                 {formik.errors.user}
                              </span>
                           ) : null}
                        </Col>
                     </Row>
                  </>

                  <>
                     <FormGroup className="d-flex mb-0 location-add-edit-fg">
                        <Label
                           for="location"
                           className="location-modal-label"
                           sm={3}
                        >
                           Warehouse
                        </Label>
                        <Col sm={8} className="input-text-container ">
                           <AutoComplete
                              handleBlur={() =>
                                 setWarehouseListing(prev => {
                                    return {
                                       ...prev,
                                       page: 1,
                                       Q: "",
                                    }
                                 })
                              }
                              onChange={handleSelectedWarehouse}
                              onInputChange={val =>
                                 handleSearch(val, setWarehouseListing)
                              }
                              placeholder="Search Warehouse"
                              classNamePrefix="add-new-req"
                              customStyles={AutoCompleteStyling}
                              options={warehouseListing?.data}
                              value={formik.values.warehouse}
                              isLoading={warehouseListing?.fetching}
                           />
                        </Col>
                     </FormGroup>
                     <Row className="mb-3">
                        <Col sm={3} lg={3} md={3}></Col>
                        <Col sm={8}>
                           {formik.touched.warehouse &&
                           formik.errors.warehouse ? (
                              <span className="error fs-10">
                                 {formik.errors.warehouse}
                              </span>
                           ) : null}
                        </Col>
                     </Row>
                  </>
               </div>
               <div className="d-flex justify-content-center align-items-center">
                  <Button
                     isLoading={isLoading}
                     type="submit"
                     title={btnText ? btnText : undefined}
                     className="gt-btn-grad-primary my-4 button-width-vendor"
                  />
               </div>
            </form>
         </CardBody>
      </div>
   )
}

export default AddEditDepartment
