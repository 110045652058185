import * as Yup from "yup"

export const VARIATIONS = {
   Yes: "Yes",
   No: "No",
}

export const ADD_NOTE = {
   Yes: "Yes",
   No: "No",
}

export const FIELDS_NAME = {
   NAME: "name",
   DEFAULT_ITEM: "defaultItem",
   DESCRIPTION: "description",
   PRICE: "price",
   QUANTITY: "quantity",
   VARIATION: "variations",
   VARIATION_TYPE: "variationType",
   ADD_NOTE: "addNote",
   STATUS: "status",
}

export const initialValues = {
   [FIELDS_NAME.DEFAULT_ITEM]: null,
   [FIELDS_NAME.DESCRIPTION]: "",
   [FIELDS_NAME.QUANTITY]: 0,
   [FIELDS_NAME.NAME]: null,
   [FIELDS_NAME.STATUS]: "ACTIVE",
   [FIELDS_NAME.PRICE]: "",
   [FIELDS_NAME.VARIATION]: VARIATIONS.No,
   [FIELDS_NAME.VARIATION_TYPE]: "SINGLE",
   [FIELDS_NAME.ADD_NOTE]: ADD_NOTE.No,
}

export const validationSchema = Yup.object({
   // [FIELDS_NAME.QUANTITY]: Yup.number("Invalid quantity")
   //    .notRequired("Quantity is required")
   //    .min(1, "Quantity cannot be less than 1"),
   [FIELDS_NAME.NAME]: Yup.string().required("Enter item name").nullable(),
   [FIELDS_NAME.DESCRIPTION]: Yup.string().required("Enter item description"),
   [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
   [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
      .required("Enter valid price")
      .test("", "Price must be between 1 and 9 digits", item => {
         let num = item?.toString()?.length
         return num <= 9 && num >= 1
      }),
   [FIELDS_NAME.VARIATION]: Yup.string().required("Select one"),
   [FIELDS_NAME.VARIATION_TYPE]: Yup.string().when(FIELDS_NAME.VARIATION, {
      is: selectedValue => {
         return selectedValue === "Yes"
      },
      then: () => Yup.string().required("Select one"),
   }),
   // [FIELDS_NAME.DEFAULT_ITEM]: Yup.object().when(FIELDS_NAME.VARIATION, {
   //    is: selectedValue => {
   //       return selectedValue === "No"
   //    },
   //    then: () => Yup.object().required("").nullable().notRequired(),
   // }),

   [FIELDS_NAME.ADD_NOTE]: Yup.string().required("Select status"),
})
