import React from "react"
import { useParams } from "react-router-dom"

import ManageTrailerManagement from "./ManageTrailerManagement"

const CloneTrailerManagement = () => {
   const { trailerId } = useParams()

   return (
      <ManageTrailerManagement
         id={trailerId}
         isDetailView={null}
         isCloning={true}
      />
   )
}

export default CloneTrailerManagement
