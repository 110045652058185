import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import toast from "react-hot-toast"

import { DataTable } from "components/DataTable"
import editImg from "../../../../assets/images/icons/edit-icon.svg"
import greenCheckImg from "../../../../assets/images/check-green.png"
import redCrossImg from "../../../../assets/images/cross-red.png"
import { Modal } from "components/Modal"
import { Pagination } from "components/Pagination"
import DeleteModal from "../modals/DeleteModal"
import { Header } from "../../Common/Header"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
   DepartmentListing,
   AddDepartment,
   UpdateDepartment,
   DeleteDepartment,
} from "api/api.service"
import { truncateString } from "utils/commonUtils"
import { APP_ROUTES, getRoute } from "helpers/routeHelpers"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import { UserRoles } from "../../../../constants"
import AddEditDepartment from "../modals/AddEditDepartment"

const COLUMNS = {
   S_NO: "S.No",
   DEPARMENT_NAME: "Stations",
   ASSIGN_TO: "Department Manager",
   ADDRESS: "Address",
   LOCATION: "Location",
   PRODUCTION_TIME: "Production Time (hrs)",
   ITEM_CATALOG: "Item Catalog",
   ACTIONS: "Action",
}

const MODAL_ACTIONS = {
   ADD: "1",
   EDIT: "2",
   DELETE: "3",
}

const dataKey = "data"
const totalKey = "total"
const CHARACTER_LIMIT = 20

const DepartmentLocationListing = ({}) => {
   let history = useHistory()
   const userType = useSelector(state => state?.userAuth?.user?.type)

   const [actionStates, setActionStates] = useState({
      isAddDepartment: null,
      isEditDepartment: null,
      isDeleteModal: null,
   })
   const [currentActionKey, setCurrentActionKey] = useState(null)
   const [selectedRowData, setSelectedRowData] = useState(null)
   const [isLoading, setIsLoading] = useState(false)
   const [isDataUpdated, setIsDataUpdated] = useState(false)
   const [recordDataID, setRecordDataID] = useState(undefined)
   const [filters, setFilters] = useState(null)
   const {
      data,
      isFetching,
      page,
      searchText,
      total,
      pageSize,
      request,
      onChangePageSize,
      handlePageClick,
      handleSearchChange,
   } = useLocalPaginatedRequest({
      requestFn: DepartmentListing,
      params: {
         ...(filters?.Status && { Status: filters?.Status }),
      },
      deps: [filters, isDataUpdated],
      dataKey: dataKey,
      totalKey: totalKey,
   })

   const handleEditDepartment = data => {
      setSelectedRowData(data)
      setCurrentActionKey(MODAL_ACTIONS.EDIT)
      setActionStates(prev => ({ ...prev, isEditDepartment: true }))
   }

   const handleDeleteModal = data => {
      setRecordDataID(data.id)
      setCurrentActionKey(MODAL_ACTIONS.DELETE)
      setActionStates(prev => ({ ...prev, isDeleteModal: true }))
   }

   let currentAction = {
      [MODAL_ACTIONS.ADD]: {
         title: "Add New Station",
         btnText: "Add Station",
      },
      [MODAL_ACTIONS.EDIT]: {
         title: "Edit Station",
         btnText: "Edit Station",
      },
      [MODAL_ACTIONS.DELETE]: {
         title: "Delete Station",
         btnText: "Delete Station",
         value: "Department",
      },
   }

   const isFinanceUser = Boolean(userType == UserRoles.Finance)

   const handleCloseModal = () => {
      setActionStates({
         isAddDepartment: null,
         isEditDepartment: null,
         isDeleteModal: null,
      })
      setCurrentActionKey(null)
   }

   const handleAddDepartment = () => {
      setSelectedRowData(null)
      setCurrentActionKey(MODAL_ACTIONS.ADD)
      setActionStates(prev => ({ ...prev, isAddDepartment: true }))
   }

   const handleSubmit = values => {
      let { name, address, location, user, warehouse } = values
      let payload = {
         warehouseId: warehouse.value,
         departmentName: name,
         ...(address && { address: address }),
         ...(location && { location: location }),
         userId: user.value,
         status: "ACTIVE",
      }

      // if (actionStates.isEditDepartment) {
      //     payload = {
      //         departmentName: name,
      //         address,
      //         location,
      //         status: "ACTIVE",
      //     }
      // } else if (actionStates.isAddDepartment) {
      //     payload = {
      //         warehouseId: warehouse.value,
      //         departmentName: name,
      //         address,
      //         location,
      //         userId:
      //             currentActionKey == "2"
      //                 ? selectedRowData.userId
      //                 : user.value,
      //         status: "ACTIVE",
      //     }
      // }

      handleSumbitForm(payload)
   }

   const handleSumbitForm = async formBody => {
      try {
         setIsLoading(true)
         let response

         if (currentActionKey == "2") {
            response = await UpdateDepartment(
               selectedRowData.id,
               formBody,
               false
            )
         } else {
            response = await AddDepartment(formBody, false)
         }
         updateAPISuccess()
         toast.success("Success !")
         request()
         handleCloseModal()
      } catch (err) {
         // toast.error(err.message)
      } finally {
         setIsLoading(false)
      }
   }

   const deleteRecordHandler = async () => {
      try {
         setIsLoading(true)
         let response = await DeleteDepartment(recordDataID)
         updateAPISuccess()
         toast.success("Station has been deleted successfully!")
         request()
         handleCloseModal()
      } catch (err) {
         // toast.error(err.message)
      } finally {
         setIsLoading(false)
      }
   }

   const updateAPISuccess = () => setIsDataUpdated(!isDataUpdated)

   const handleDepartmentCatalog = data => {
      history.push(
         getRoute(APP_ROUTES.ADMIN_LOCATION_DEPARTMENT_ITEM_CATALOG, {
            departmentId: data?.id,
         })
      )
   }

   return (
      <>
         <Row>
            <Col>
               <Header
                  btnTitle="Add New Station"
                  onClick={handleAddDepartment}
                  searchText={searchText}
                  onChangeHandler={handleSearchChange}
                  flexResponsive="location-flex-responsiveness"
                  searchResponsiveness="location-searchResponsiveness"
                  flexResponsiveInner="location-flexResponsiveInner"
                  headerBtnResponsive="ad-location-header-btn-responsive"
                  SearchBoxContainer="admin-location-search-box-container"
               />
            </Col>
            <Col md={12}>
               <DataTable
                  data={data}
                  loading={isFetching}
                  className="location-data-container mt-3"
                  tableClasses="ad-location-table"
                  config={(function () {
                     var arr = [
                        {
                           title: COLUMNS.S_NO,
                           className: "black-color fm-ftwg-600  ",
                           render: data => data?.id || "-",
                        },
                        {
                           title: COLUMNS.DEPARMENT_NAME,
                           className: "",
                           render: data => data?.departmentName || "-",
                        },
                        {
                           title: COLUMNS.ASSIGN_TO,
                           className: "",
                           render: data =>
                              data?.departmentAssignments[0]
                                 ? data?.departmentAssignments[0]["user"][
                                      "firstName"
                                   ] +
                                   " " +
                                   data?.departmentAssignments[0]?.["user"]?.[
                                      "lastName"
                                   ]
                                 : "-",
                        },
                        {
                           title: COLUMNS.ADDRESS,
                           className: "",
                           render: data => {
                              if (data?.address?.length > CHARACTER_LIMIT) {
                                 return (
                                    <div data-container={data?.address}>
                                       {truncateString(
                                          data?.address,
                                          CHARACTER_LIMIT
                                       )}
                                    </div>
                                 )
                              } else {
                                 return data?.address || "-"
                              }
                           },
                        },
                        {
                           title: COLUMNS.LOCATION,
                           className: "",
                           render: data => data?.location || "-",
                        },

                        // To be uncomment when on pr
                        // {
                        //     title: COLUMNS.PRODUCTION_TIME,
                        //     className: "",
                        //     render: data => data?.productionTime || "-",
                        // },

                        {
                           title: COLUMNS.ACTIONS,
                           className: "",
                           render: data => (
                              <div className="d-flex justify-content-between location-icon-container">
                                 <span
                                    className="pointer"
                                    onClick={e => handleEditDepartment(data)}
                                 >
                                    <img src={editImg} />
                                 </span>

                                 {!isFinanceUser && (
                                    <span>
                                       <img
                                          src={
                                             data.departmentCatalogStatus
                                                ? greenCheckImg
                                                : redCrossImg
                                          }
                                       />
                                    </span>
                                 )}
                              </div>
                           ),
                        },
                     ]

                     if (!isFinanceUser) {
                        arr.splice(arr.length - 1, 0, {
                           title: COLUMNS.ITEM_CATALOG,
                           className: "",
                           render: data => (
                              <div
                                 style={{
                                    textDecoration: "underline",
                                    fontWeight: "500",
                                    color: "#1374D5",
                                    cursor: "pointer",
                                 }}
                                 onClick={() => handleDepartmentCatalog(data)}
                              >
                                 {data?.departmentsCatalog[0]
                                    ?.departmentsItemsCatalog?.length || "0"}
                              </div>
                           ),
                        })
                     }
                     return arr
                  })()}
               />
            </Col>
         </Row>

         <Pagination
            currentPage={page}
            totalData={total}
            onPageClick={handlePageClick}
         />

         <Modal
            isOpen={!!currentAction[currentActionKey]}
            handleModalToggling={handleCloseModal}
            bodyClassName="p-0"
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="modal-header-container"
            size="lg"
            modalTitle={currentAction[currentActionKey]?.title}
         >
            {(actionStates.isAddDepartment ||
               actionStates.isEditDepartment) && (
               <AddEditDepartment
                  btnText={currentAction[currentActionKey].btnText}
                  activeModalAction={currentActionKey}
                  data={selectedRowData}
                  onSubmitForm={handleSubmit}
                  isLoading={isLoading}
               />
            )}
            {actionStates.isDeleteModal && (
               <DeleteModal
                  value={currentAction[currentActionKey]?.value}
                  handleModalClose={handleCloseModal}
                  handleDeleteRecord={deleteRecordHandler}
               />
            )}
         </Modal>
      </>
   )
}

export default DepartmentLocationListing
