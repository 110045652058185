import React, { useState } from "react"
import { CardBody } from "reactstrap"
import toast from "react-hot-toast"

import { Button } from "components/Button"
import { Modal } from "components/Modal"

const DeleteModal = ({
   id,
   isOpen,
   toggleFunction,
   title,
   apiFunction,
   requestFunction,
}) => {
   const [loading, setLoading] = useState(false)

   let handleDelete = async () => {
      try {
         setLoading(true)
         let res = await apiFunction(id)
         toast.success(`${title} has been deleted !`)
         requestFunction()
      } catch (err) {
      } finally {
         setLoading(false)
         toggleFunction(prev => !prev)
      }
   }

   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={() => toggleFunction(prev => !prev)}
         bodyClassName="p-0"
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container"
         size="md"
         backdrop="static"
         modalTitle={`Delete ${title}`}
      >
         <div className="d-flex flex-column align-items-center  mb-4 mt-4">
            <CardBody>
               <h5 className="modal-label">
                  {` Are you sure, you want to delete this ${title}?`}
               </h5>
            </CardBody>
            <div className="d-flex justify-content-between mt-5">
               <Button
                  onClick={() => toggleFunction(prev => !prev)}
                  title="Cancel"
                  className="vendor-modal-cancel-btn"
               />

               <Button
                  onClick={handleDelete}
                  title="Delete"
                  className="gt-btn-grad-primary vendor-modal-del-btn"
                  isLoading={loading}
               />
            </div>
         </div>
      </Modal>
   )
}

export default DeleteModal
