import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
   AccordionBody,
   AccordionHeader,
   AccordionItem,
   UncontrolledAccordion,
} from "reactstrap"

import { GetPackagesListing } from "api/api.service"
import { Loader } from "components/Loader"
import { ConfiguratorStatus } from "constants"
import ImageGallery from "pages/Configurator/CommonUI/ImageGallery/ImageGallery"

const Packages = () => {
   let { trailerId } = useParams()
   const [packageData, setPackageData] = useState([])
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      getPackages()
   }, [trailerId])

   const getPackages = async () => {
      try {
         setLoading(true)
         let res = await GetPackagesListing({ trailerId: trailerId, limit: -1 })
         setPackageData(res)
      } catch (error) {
      } finally {
         setLoading(false)
      }
   }

   if (loading) {
      return <Loader />
   }

   if (packageData.total < 1) {
      return (
         <div className="d-flex justify-content-center align-items-center">
            <h2 className="p-4">No Data</h2>
         </div>
      )
   }

   return (
      <UncontrolledAccordion defaultOpen={0} className="my-4">
         {packageData?.data?.map((pkgObj, ind) => (
            <AccordionItem key={ind}>
               <AccordionHeader targetId={ind}>
                  <div className="accordion-title-container d-flex gap-4">
                     <h4 className="accordion-title-blue  p-0 m-0">
                        {pkgObj.packageName}
                     </h4>
                     <h4 className="accordion-title-black p-0 m-0">
                        ${pkgObj.price}
                     </h4>
                     <div
                        className={`configurator-status ${ConfiguratorStatus.getLabelClass(
                           pkgObj.status
                        )}`}
                     >
                        <span>
                           {ConfiguratorStatus.getDisplayTextKey(pkgObj.status)}
                        </span>
                     </div>
                  </div>
               </AccordionHeader>
               <AccordionBody accordionId={ind}>
                  <h4 className="accordion-title-black">Description</h4>
                  <p>{pkgObj.description}</p>
                  <h4 className="accordion-title-black">Images</h4>

                  <ImageGallery
                     previousImages={pkgObj.images}
                     previewOnly
                     isDetailView
                  />
               </AccordionBody>
            </AccordionItem>
         ))}
      </UncontrolledAccordion>
   )
}

export default Packages
