import React, { useState, useEffect } from "react"
import { Col, Row, Form } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import * as Yup from "yup"
import { useParams } from "react-router-dom"

import { Modal } from "../../../../components/Modal"
import { TextInput } from "../../../../components/TextInput"
import { Button } from "../../../../components/Button"
import FormikErrorText from "../../../../components/FormikErrorText/FormikErrorText"
import ActiveInactiveSwitch from "../ActiveInactiveSwitch/ActiveInactiveSwitch"
import { AutoComplete } from "../../../../components/AutoComplete"
import { GetBaseFeaturesCategoriesListing } from "api/api.service"
import { AutoCompleteStyling } from "../../../Common/AutoCompleteStyling"
import useAsyncSelect from "hooks/useAsyncSelect"

export const FIELDS_NAME = {
   NAME: "name",
   STATUS: "status",
   BASE_FEATURE: "baseFeature",
}

const ManageSettingsCategory = ({
   TITLES,
   id = "",
   textFieldLabel = "",
   textFieldPlaceholder = "",
   entityName = "",
   entityApiKey = "",
   subTitle = "",
   isOpen = false,
   payloadData = {},
   statusConstObj = {},
   addApiFunc = () => {},
   updateApiFun = () => {},
   fetchApiFunc = () => {},
   onToggleModal = () => {},
   onSuccess = () => {},
   hasBaseFeature = false,
}) => {
   const { trailerId } = useParams()

   const [status, setStatus] = useState(true)
   const [isLoading, setIsLoading] = useState(false)
   const [isFetching, setIsFetching] = useState(false)
   const formik = useFormik({
      enableReinitialze: true,
      initialValues: {
         [FIELDS_NAME.NAME]: "",
         [FIELDS_NAME.STATUS]: "ACTIVE",
         ...(hasBaseFeature && { [FIELDS_NAME.BASE_FEATURE]: null }),
      },
      validationSchema: Yup.object({
         [FIELDS_NAME.NAME]: Yup.string()
            .required("Enter name")
            .min(3, "Enter  name between 3 to 250 characters")
            .max(250, "Enter  name between 3 to 250 characters"),
         [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
         ...(hasBaseFeature && {
            [FIELDS_NAME.BASE_FEATURE]: Yup.object()
               .nullable()
               .required("Base feature is required"),
         }),
      }),

      onSubmit: values => {
         handleSubmit(values)
      },
   })

   const {
      setSelectedOption: setSelectedBaseFeature,
      selectEntity: baseFeaturesOptions,
      setSelectEntity: setBaseFeaturesOptions,
      handleSearchOnOptions: handleSearchBaseFeature,
      selectedOption: selectedBaseFeature,
      handleSelectAOption: handleSelectABaseFeatureOption,
   } = useAsyncSelect({
      apiFunc: GetBaseFeaturesCategoriesListing,
      labelKey: "name",
      valueKey: "id",
      restrictExecutionOnRender: !hasBaseFeature,
      params: {
         trailerId: trailerId,
      },
      onOptionSelect: option => {
         formik.setFieldValue([FIELDS_NAME.BASE_FEATURE], option)
      },
   })

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
      }
   }, [id])

   const handleStatusConversion = status => {
      return status == statusConstObj.Active ? true : false
   }

   const handleSubmit = values => {
      let bodyData = {
         [entityApiKey]: values[FIELDS_NAME.NAME]?.trim(),
         status: values[FIELDS_NAME.STATUS],
         ...(hasBaseFeature && {
            configuratorBaseFeaturesId: values[FIELDS_NAME.BASE_FEATURE].value,
         }),
         ...payloadData,
      }

      if (id) {
         handleUpdate(bodyData)
      } else {
         handleAdd(bodyData)
      }
   }

   const handleUpdate = async data => {
      try {
         setIsLoading(true)

         let res = await updateApiFun(id, data)
         toast.success(`${entityName} has been updated successfully !`)
         handleModalClose()
         onSuccess()
      } finally {
         setIsLoading(false)
      }
   }
   const handleAdd = async data => {
      try {
         setIsLoading(true)

         let res = await addApiFunc(data)
         toast.success(`${entityName} has been added successfully !`)
         onSuccess()
         handleModalClose()
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleFetchDetails = async () => {
      if (!id) return

      try {
         setIsFetching(true)
         let res = await fetchApiFunc(id)

         setSelectedBaseFeature({
            label: res?.configuratorBaseFeatures?.name,
            value: res?.configuratorBaseFeatures?.id,
         })
         formik.setValues({
            [FIELDS_NAME.NAME]: res?.[entityApiKey],
            [FIELDS_NAME.STATUS]: res.status,
            ...(hasBaseFeature && {
               [FIELDS_NAME.BASE_FEATURE]: {
                  label: res.configuratorBaseFeatures.name,
                  value: res.configuratorBaseFeatures.id,
               },
            }),
         })

         setStatus(handleStatusConversion(res.status))
      } finally {
         setIsFetching(false)
      }
   }

   const handleModalClose = () => {
      formik.resetForm()
      setSelectedBaseFeature(null)
      onToggleModal()
      setStatus(true)
   }

   const handleSetStatus = evt => {
      let { name } = evt.target
      let alteredStatus = !status
      setStatus(alteredStatus)
      formik.setFieldValue(
         name,
         alteredStatus ? statusConstObj.Active : statusConstObj.Inactive
      )
   }

   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={handleModalClose}
         bodyClassName=""
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container mb-0"
         size="md"
         backdrop="static"
         modalTitle={id ? TITLES.MODAL_UPDATE_TITLE : TITLES.MODAL_ADD_TITLE}
         scrollable
      >
         <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
            <div className="d-flex justify-content-center mb-3">
               <h3 className="m-0 p-0 fw-bold text-blue">{subTitle}</h3>
            </div>
            <Row>
               <Col xs={12}>
                  <Form onSubmit={formik.handleSubmit}>
                     {hasBaseFeature && (
                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Base Features
                           </label>
                           <div className="col-md-9">
                              <AutoComplete
                                 handleBlur={() =>
                                    setBaseFeaturesOptions(prev => {
                                       return {
                                          ...prev,
                                          page: 1,
                                          Q: "",
                                       }
                                    })
                                 }
                                 onChange={handleSelectABaseFeatureOption}
                                 onInputChange={handleSearchBaseFeature}
                                 placeholder="Search base features"
                                 classNamePrefix="add-new-req"
                                 customStyles={AutoCompleteStyling}
                                 options={baseFeaturesOptions?.data}
                                 // isClearable={true}
                                 value={selectedBaseFeature}
                                 isLoading={baseFeaturesOptions?.fetching}
                                 name={FIELDS_NAME.BASE_FEATURE}
                              />
                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.BASE_FEATURE}
                              />
                           </div>
                        </Row>
                     )}

                     <Row className="mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           {textFieldLabel}
                        </label>
                        <div className="col-md-9">
                           <TextInput
                              placeholder={textFieldPlaceholder}
                              name={FIELDS_NAME.NAME}
                              {...formik.getFieldProps(FIELDS_NAME.NAME)}
                           />
                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.NAME}
                           />
                        </div>
                     </Row>

                     <Row className="mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Status
                        </label>
                        <div className="col-md-9">
                           <ActiveInactiveSwitch
                              isChecked={status}
                              name={FIELDS_NAME.STATUS}
                              onChange={handleSetStatus}
                           />
                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.STATUS}
                           />
                        </div>
                     </Row>

                     <div className="d-flex justify-content-center mt-3 mt-2">
                        <Button
                           isLoading={isLoading}
                           type={"submit"}
                           title={
                              id ? TITLES.UPDATE_BTN_TEXT : TITLES.ADD_BTN_TEXT
                           }
                           className="header-button"
                        />
                     </div>
                  </Form>
               </Col>
            </Row>
         </div>
      </Modal>
   )
}

export default React.memo(ManageSettingsCategory)
