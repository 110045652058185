import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { useHistory } from "react-router-dom"
import toast from "react-hot-toast"

import deleteIcon from "../../assets/images/icons/finance-vendor-mang-del-icon.svg"
import cloneIcon from "../../assets/images/icons/clone-icon.svg"
import eyeIcon from "../../assets/images/icons/eye-icon.svg"
import editIcon from "../../assets/images/icons/edit-icon.svg"
import { DataTable } from "../../components/DataTable"
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest"
import { Pagination } from "../../components/Pagination"
import {
   DeleteTrailerType,
   GeTrailersTypesListing,
} from "../../api/api.service"
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers"
import { SearchBox } from "../../components/SearchBox"
import { Button } from "../../components/Button"
import { TrailerTypeColors } from "../../constants"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import { Modal } from "components/Modal"
import DeleteTrailerModal from "./DeleteTrailer"

const COLUMNS = {
   TRAILER_NO: "Trailer No.",
   TRAILER_TYPE: "Trailer Type",
   COLORS: "Colors",
   DATE_ADDED: "Date Added",
   ACTIONS: "Actions",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const TrailerManagementListing = ({}) => {
   const history = useHistory()
   const [selection, setSelection] = useState({})
   const [loading, setLoading] = useState(false)

   const {
      data,
      isFetching,
      page,
      searchText,
      pageSize,
      total,
      handleSearchChange,
      request,
      handlePageClick,
   } = useLocalPaginatedRequest({
      requestFn: GeTrailersTypesListing,
      params: {},
      deps: [],
      dataKey: DATA_KEY,
      totalKey: TOTAL_KEY,
   })

   const [eventsType, setEventsType] = useState({
      isDelete: null,
   })

   const handleDeleteInit = obj => {
      setEventsType(prev => ({ ...prev, isDelete: true }))
      setSelection(obj)
   }

   let handleSubmit = () => {
      handleDelete()
   }

   let handleDelete = async () => {
      try {
         setLoading(true)
         let res = await DeleteTrailerType(selection.id)
         toast.success("Trailer type has been deleted !")
         request()
         handleCloseModal()
      } catch (err) {
      } finally {
         setLoading(false)
      }
   }

   const handleCloseModal = () => {
      setEventsType({
         isDelete: null,
      })
   }

   const handleUpdate = obj =>
      history.push(
         getRoute(APP_ROUTES.TRAILERS_MANAGEMENT_UPDATE_TRAILER, {
            trailerId: obj.id,
         })
      )
   const handleClone = obj =>
      history.push(
         getRoute(APP_ROUTES.TRAILERS_MANAGEMENT_CLONE_TRAILER, {
            trailerId: obj.id,
         })
      )

   const handleViewDetails = obj =>
      history.push(
         getRoute(APP_ROUTES.TRAILERS_MANAGEMENT_TRAILER_DETAILS, {
            trailerId: obj.id,
         })
      )
   const handleAdd = () =>
      history.push(getRoute(APP_ROUTES.TRAILERS_MANAGEMENT_ADD_TRAILER))

   return (
      <>
         <div className="mb-3 trailer-container">
            <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
               <h1 className="header-title mb-0">Trailer Management</h1>

               <Button
                  className={`header-button headerButtonResponsive`}
                  title="Add Trailer Type"
                  onClick={handleAdd}
               />
            </div>
         </div>

         <div className="mb-3">
            <div className="d-flex align-items-center justify-content-end admin-inventory-management-general-inventory-responsiveness ">
               <div className="ad-location-search-box">
                  <SearchBox
                     inputClasses="vendor-search-input input-search"
                     labelClass="d-none"
                     placeholder="Search"
                     searchIcon={true}
                     searchIconClass="vendor-table-search-icon"
                     conClass="vendor-table-search-inner-class"
                     searchText={searchText}
                     onChange={handleSearchChange}
                  />
               </div>
            </div>
         </div>
         <Row>
            <Col md={12}>
               <DataTable
                  data={data}
                  tableClasses="trailer-management-table"
                  className="table-responsive"
                  loading={isFetching}
                  config={[
                     {
                        title: COLUMNS.TRAILER_NO,
                        className: "",
                        render: data => data?.trailerNo || "-",
                     },

                     {
                        title: COLUMNS.TRAILER_TYPE,
                        className: "",
                        render: data => data?.trailerType || "-",
                     },

                     {
                        title: COLUMNS.COLORS,
                        className: "",
                        render: data => {
                           return (
                              <div className="d-flex justify-content-start align-items-center flex-wrap gap-1">
                                 {data.colors.map((item, ind) => (
                                    <div
                                       key={ind}
                                       className={`${TrailerTypeColors.getLabelClass(
                                          item
                                       )}`}
                                    ></div>
                                 ))}
                              </div>
                           )
                        },
                     },

                     {
                        title: COLUMNS.DATE_ADDED,
                        className: "",
                        render: data =>
                           convertDateTime({
                              date: data.createdAt,
                              customFormat:
                                 dateTimeFormat.casitaCustomDateFormat,
                              dateOnly: true,
                           }),
                     },

                     {
                        title: COLUMNS.ACTIONS,
                        render: data => (
                           <div className="d-flex gap-2 pointer">
                              <img
                                 src={eyeIcon}
                                 onClick={() => handleViewDetails(data)}
                              />

                              <img
                                 src={editIcon}
                                 onClick={() => handleUpdate(data)}
                              />
                              {/* <img
                                 style={{
                                    transform: "scale(1.5)",
                                    transformOrigin: "center",
                                 }}
                                 src={cloneIcon}
                                 onClick={() => handleClone(data)}
                              /> */}

                              <img
                                 src={deleteIcon}
                                 onClick={() => handleDeleteInit(data)}
                              />
                           </div>
                        ),
                     },
                  ]}
               />
            </Col>
            <Pagination
               currentPage={page}
               totalData={total}
               onPageClick={handlePageClick}
            />
         </Row>

         <Modal
            isOpen={eventsType.isDelete}
            handleModalToggling={handleCloseModal}
            bodyClassName="p-0"
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="header-container"
            size="md"
            backdrop="static"
            modalTitle={"Delete Trailer Type"}
         >
            <DeleteTrailerModal
               onDeleteClick={handleSubmit}
               onCancelClick={handleCloseModal}
               isLoading={loading}
            />
         </Modal>
      </>
   )
}

export default TrailerManagementListing
