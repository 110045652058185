import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"

import { APP_ROUTES } from "helpers/routeHelpers"
import { NavTabsRoutingBasedButtons as NavButtons } from "../../AdminApp/Common/NavTabsRoutingBased"
import WarehouseIconActive from "../../../assets/images/icons/warehouse.png"
import WarehouseIcon from "../../../assets/images/icons/warehouse-deactive.png"
import DepartmentIconActive from "../../../assets/images/icons/storeIcon.png"
import DepartmentIcon from "../../../assets/images/icons/storeIcon-deactive.png"
import WarehouseListing from "./WarehouseCountsheetListing"
import DepartmentListing from "./DepartmentCountsheetListing"

const CountsheetListing = () => {
   const history = useHistory()
   const pathURL = history.location.pathname
   const [isActive, setIsActive] = useState({
      stationCountsheet: null,
      warehouseCountsheet: null,
   })

   useEffect(() => {
      const isDepartment =
         pathURL === APP_ROUTES.COUNT_SHEET
            ? false
            : pathURL === APP_ROUTES.COUNT_SHEET_STATIONS
            ? true
            : false
      const isWarehouse =
         pathURL === APP_ROUTES.COUNT_SHEET
            ? true
            : pathURL === APP_ROUTES.COUNT_SHEET_WAREHOUSE
            ? true
            : false
      setIsActive({
         stationCountsheet: isDepartment,
         warehouseCountsheet: isWarehouse,
      })
   }, [pathURL])

   const handleStationCountsheetClick = () => {
      setIsActive({
         ...isActive,
         stationCountsheet: true,
         warehouseCountsheet: false,
      })
      history.push(APP_ROUTES.COUNT_SHEET_STATIONS)
   }

   const handleWarehouseCountsheetClick = () => {
      setIsActive({
         ...isActive,
         stationCountsheet: false,
         warehouseCountsheet: true,
      })
      history.push(APP_ROUTES.COUNT_SHEET_WAREHOUSE)
   }
   const mainBtnsConfig = [
      {
         title: "Warehouse(s)",
         onClick: handleWarehouseCountsheetClick,
         classesButton: "al-nav-link",
         identifier: "warehouseCountsheet",
         icon: WarehouseIcon,
         activeIcon: WarehouseIconActive,
      },
      {
         title: "Stations",
         onClick: handleStationCountsheetClick,
         classesButton: "al-nav-link",
         identifier: "stationCountsheet",
         icon: DepartmentIcon,
         activeIcon: DepartmentIconActive,
      },
   ]

   const routesConfig = [
      {
         name: "Station",
         isExact: true,
         component: DepartmentListing,
         path: APP_ROUTES.COUNT_SHEET_STATIONS,
      },

      {
         name: "Warehouse",
         isExact: true,
         component: WarehouseListing,
         path: APP_ROUTES.COUNT_SHEET_WAREHOUSE,
      },
   ]

   return (
      <>
         <Row>
            <Col className="col-12">
               <div className="d-flex align-items-baseline">
                  <h1>Countsheets</h1>
               </div>

               <div className="admin-location mb-3">
                  <div className="al-navtabs-main">
                     <div className="al-nav-tabs">
                        <div className="al-nav-item d-flex">
                           <NavButtons
                              data={mainBtnsConfig}
                              classes={"ps-1 d-inline-block"}
                              isActive={isActive}
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <Switch>
                  {routesConfig.map(
                     ({ name, isExact, path, component }, idx) => (
                        <Route
                           key={idx}
                           name={name}
                           exact={isExact}
                           path={path}
                           component={component}
                        />
                     )
                  )}
                  <Redirect to={APP_ROUTES.COUNT_SHEET_WAREHOUSE} />
               </Switch>
            </Col>
         </Row>
      </>
   )
}

export default CountsheetListing
