import React from "react"
import { CardBody } from "reactstrap"

import { Button } from "components/Button"

const DeleteTrailerModal = ({
   entity = "Trailer type",
   isLoading,
   onDeleteClick,
   onCancelClick,
}) => {
   return (
      <div className="d-flex flex-column align-items-center  mb-4 mt-4">
         <CardBody>
            <h5 className="modal-label">
               {`Are you sure, you want to delete this ${entity}?`}
            </h5>
         </CardBody>
         <div className="d-flex justify-content-between mt-5">
            <Button
               onClick={onCancelClick}
               title="Cancel"
               className="vendor-modal-cancel-btn"
            />

            <Button
               onClick={onDeleteClick}
               title="Delete"
               className="gt-btn-grad-primary vendor-modal-del-btn"
               isLoading={isLoading}
            />
         </div>
      </div>
   )
}

export default DeleteTrailerModal
