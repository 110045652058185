import React from "react"
import { CardBody } from "reactstrap"

import { Button } from "components/Button"
import { Modal } from "components/Modal"

const ConfirmationModal = ({
   actionName = "",
   entityName = "",
   isLoading,
   handleNo,
   handleYes,
   isOpen,
   toggleFunction,
}) => {
   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={toggleFunction}
         bodyClassName="p-0"
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container"
         size="md"
         backdrop="static"
         modalTitle={`${actionName} ${entityName}`}
      >
         <div className="d-flex flex-column align-items-center  mb-4 mt-4">
            <CardBody>
               <h5 className="location-modal-label">
                  {` Are you sure, you want to ${actionName
                     .toString()
                     .toLowerCase()} this ${entityName
                     .toString()
                     .toLowerCase()}?`}
               </h5>
            </CardBody>
            <div className="d-flex justify-content-between mt-5">
               <Button
                  type=""
                  title="Cancel"
                  className="location-modal-cancel-btn"
                  onClick={toggleFunction}
               />

               <Button
                  type=""
                  title={actionName}
                  className="gt-btn-grad-primary location-modal-del-btn"
                  onClick={handleYes}
                  isLoading={isLoading}
               />
            </div>
         </div>
      </Modal>
   )
}

export default ConfirmationModal
