import React from "react"

import crossIcon from "../../../../assets/images/cross-red.png"
import "./PreviewImage.scss"
import { Loader } from "components/Loader"

const PreviewImage = props => {
   let {
      id,
      loading,
      containerClasses,
      crossClasses,
      handleRemoveImage,
      imageToPreview,
      imageClasses,
      hideCross = false,
      onClick,
      isDetailView,
   } = props
   return (
      <div className={`preview-container ${containerClasses}`}>
         {/* Cross Button  */}
         {!hideCross &&
            (id && loading ? null : (
               <img
                  className={`cross-button ${crossClasses}`}
                  src={crossIcon}
                  onClick={handleRemoveImage}
               />
            ))}
         {/* Image  */}
         {id && loading ? (
            <div
               className={"preview-image rounded skeleton-image-gallery"}
            ></div>
         ) : (
            <img
               src={imageToPreview}
               className={`${
                  isDetailView && "pointer"
               } preview-image rounded  ${imageClasses}`}
               onClick={() => onClick(imageToPreview)}
            />
         )}
      </div>
   )
}

export default PreviewImage
