import * as Yup from "yup"

export const FIELDS_NAME = {
   NAME: "name",
   DESCRIPTION: "description",
   STATUS: "status",
}

export const initialValues = {
   [FIELDS_NAME.DESCRIPTION]: "",
   [FIELDS_NAME.NAME]: "",
   [FIELDS_NAME.STATUS]: "ACTIVE",
}

export const validationSchema = Yup.object({
   [FIELDS_NAME.NAME]: Yup.string().required("Enter item name"),

   [FIELDS_NAME.DESCRIPTION]: Yup.string().required("Enter item description"),

   [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
})
