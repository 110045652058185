import * as Yup from "yup"
import { UserRoles } from "../../../../constants"

const PASSWORD_MUST_MATCH = "Passwords must match"

export const initialValues = {
   firstName: "",
   lastName: "",
   emailAddress: "",
   role: "",
   departmentName: "",
}

export const validationSchema = Yup.object({
   firstName: Yup.string().required("Enter First Name"),
   lastName: Yup.string().required("Enter Last Name"),
   emailAddress: Yup.string().required("Enter Email"),
   role: Yup.string().required("Enter Role"),
   departmentName: Yup.string().when("role", {
      is: UserRoles.Department,
      then: Yup.string().required("Enter Department Name"),
      otherwise: Yup.string().notRequired(),
   }),
})

export const initialValuesChangePass = {
   password: "",
   confirmPassword: "",
}

export const validationSchemaChangePass = Yup.object({
   password: Yup.string()
      .trim()
      .required("Please enter your password")
      .min(6, "Please enter a password between 6 and 20 characters")
      .max(20, "Please enter a password between 6 and 20 characters"),
   confirmPassword: Yup.string()
      .trim()
      .required("Please enter your confirm password")
      .oneOf([Yup.ref("password"), null], PASSWORD_MUST_MATCH)
      .min(6, "Please enter a password between 6 and 20 characters")
      .max(20, "Please enter a password between 6 and 20 characters"),
})
