import React from "react"

const FormikErrorText = props => {
   let { formikInstance, fieldName, errorClasses = "" } = props

   return (
      <>
         {formikInstance.touched[fieldName] &&
         formikInstance.errors[fieldName] ? (
            <small className={`error ${errorClasses}`}>
               {formikInstance.errors[fieldName]}
            </small>
         ) : null}
      </>
   )
}

export default FormikErrorText
