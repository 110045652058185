import React, { useState } from "react"
import { CardBody, Card } from "reactstrap"

import { DataTable } from "components/DataTable"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { SearchBox } from "components/SearchBox"
import { AutoComplete } from "components/AutoComplete"
import customStyles from "./AutocompleteStyling"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { CountsheetListing, WarehouseListing } from "api/api.service"
import useAsyncSelect from "hooks/useAsyncSelect"
import ItemDetails from "./ItemDetails"

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const COLUMNS = {
   SKU_NO: "SKU No.",
   ITEM_NAME: "Item Name",
   AUDITS: "Audits",
   ACTION: "Action",
}

const Listing = () => {
   const [filters, setFilters] = useState(null)
   const [selectedItemToView, setSelectedItemToView] = useState("")
   const [modalStates, setModalStates] = useState({
      viewDetailModal: false,
   })

   const {
      page,
      searchText,
      handleSearchChange,
      handlePageClick,
      pageSize,
      onChangePageSize,
      data,
      total,
      isFetching,
      request,
   } = useLocalPaginatedRequest({
      requestFn: CountsheetListing,
      params: {
         ...(filters?.length && { departmentId: filters }),
         auditCategory: "Warehouse",
      },
      deps: [filters],
      dataKey: DATA_KEY,
      totalKey: TOTAL_KEY,
   })

   const {
      setSelectedOption: setSelectedStationOption,
      selectEntity: stationOptions,
      setSelectEntity: setStationsOption,
      handleSearchOnOptions: handleSearchStations,
      selectedOption: selectedStation,
      handleSelectAOption: handleSelectAStationOption,
   } = useAsyncSelect({
      apiFunc: WarehouseListing,
      labelKey: "warehouseName",
      valueKey: "id",
      onOptionSelect: option => {
         const ids = option.map(item => item.value)
         setFilters(ids)
      },
   })

   const handleCloseModal = () => {
      setModalStates({
         viewDetailModal: false,
      })
      setSelectedItemToView("")
   }

   const handleSelectedItem = item => {
      setSelectedItemToView(item)
      setModalStates({
         ...modalStates,
         viewDetailModal: true,
      })
   }

   return (
      <>
         <Card className="mb-5">
            <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
               <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness ">
                     <div className="status-search-con wh-im-dpgi-status">
                        <AutoComplete
                           handleBlur={() =>
                              setStationsOption(prev => {
                                 return {
                                    ...prev,
                                    page: 1,
                                    Q: "",
                                 }
                              })
                           }
                           onChange={handleSelectAStationOption}
                           onInputChange={handleSearchStations}
                           placeholder="Select warehouse"
                           classNamePrefix="status-header-search-admin"
                           customStyles={customStyles.WarehouseIM}
                           options={stationOptions?.data}
                           value={selectedStation}
                           isLoading={stationOptions?.fetching}
                           isMulti
                        />
                     </div>
                     <div className="ad-location-search-box">
                        <SearchBox
                           inputClasses="vendor-search-input input-search"
                           labelClass="d-none"
                           placeholder="Search"
                           searchIcon={true}
                           searchIconClass="vendor-table-search-icon"
                           conClass="vendor-table-search-inner-class"
                           searchText={searchText}
                           onChange={handleSearchChange}
                        />
                     </div>
                  </div>
               </div>
               <DataTable
                  data={data}
                  tableClasses="ad-warehouse-general-inventory-table"
                  loading={isFetching}
                  config={[
                     {
                        title: COLUMNS.SKU_NO,
                        render: item => item.skuNo,
                     },
                     {
                        title: COLUMNS.ITEM_NAME,
                        render: item => item.itemName,
                     },
                     {
                        title: COLUMNS.AUDITS,
                        render: item => item.totalAudits,
                     },

                     {
                        title: COLUMNS.ACTION,
                        render: item => (
                           <div
                              className="d-flex align-items-center justify-content-center"
                              onClick={() => handleSelectedItem(item)}
                           >
                              <img
                                 src={ActionEyeIcon}
                                 alt="view"
                                 className="cursor-pointer"
                              />
                           </div>
                        ),
                     },
                  ]}
               />
               <Pagination
                  currentPage={page}
                  totalData={total}
                  onPageClick={handlePageClick}
                  rowLimit={pageSize}
               />
            </CardBody>
         </Card>
         <Modal
            isOpen={modalStates.viewDetailModal}
            handleModalToggling={handleCloseModal}
            sizeClasses="10"
            bodyClassName="p-0"
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="pb-0"
            className="inv-req-popup-viewdetails"
         >
            <ItemDetails
               auditFor="Warehouse"
               itemsId={selectedItemToView.itemsId}
               warehouseId={filters?.[0]}
               name={"warehouse"}
               subKey={"warehouseName"}
            />
         </Modal>
      </>
   )
}

export default Listing
