import { useEffect, useState } from "react"

import Config from "../Config"

const useAsyncSelect = ({
   dataKey = "data",
   totalKey = "total",
   applyOptionsFilter = false,
   keyToCheck = "",
   valueToMatch = "",
   customExecutionHandlers = null,
   labelKey = "",
   valueKey = "",
   defaultPage = 1,
   params = {},
   restrictExecutionOnRender = false,
   apiFunc = () => {},
   onOptionSelect = () => {},
   deps = [],
   attachSignal = true,
}) => {
   let controller = new AbortController()
   let signal = controller.signal

   const [selectedOption, setSelectedOption] = useState(null)
   const [selectEntity, setSelectEntity] = useState({
      data: [],
      fetching: false,
      page: defaultPage,
      Q: "",
      isDataAvailable: false,
   })

   useEffect(() => {
      if (!restrictExecutionOnRender) {
         handleGetSelectEntityData(selectEntity.page)
      }
      if (typeof customExecutionHandlers === "function") {
         customExecutionHandlers()
      }
      return () => {
         controller.abort()
      }
   }, [...deps, selectEntity.Q])

   const handleSearchOnOptions = value => {
      if (!value && selectEntity.Q < 1) return

      setSelectEntity(prev => {
         return {
            ...prev,
            data: [],
            page: 1,
            Q: value,
         }
      })
   }

   const handleSelectAOption = (option = "", id) => {
      setSelectedOption(option)
      onOptionSelect(option, id)
   }

   const handleGetSelectEntityData = page => {
      setSelectEntity(prev => ({ ...prev, fetching: true, data: [] }))
      apiFunc(
         {
            limit: Config.LIMIT,
            page: page,
            ...(selectEntity.Q && { Q: selectEntity.Q }),
            ...(params && params),
         },
         attachSignal && signal
      )
         .then(res => {
            setSelectEntity(prev => ({
               ...prev,
               fetching: false,
               page: page + 1,
               isDataAvailable: res?.[dataKey].length == Config.LIMIT,
               data: (() => {
                  let options = res?.[dataKey].map(item => ({
                     ...item,
                     value: item[valueKey],
                     label: item[labelKey],
                  }))

                  if (applyOptionsFilter) {
                     options = options.filter(
                        item => item[keyToCheck] != valueToMatch
                     )
                  }

                  return [...options, ...prev.data]
               })(),
            }))
         })
         .finally(() => {
            setSelectEntity(prev => ({
               ...prev,
               fetching: false,
            }))
         })
   }

   return {
      selectEntity,
      selectedOption,
      setSelectEntity,
      handleGetSelectEntityData,
      handleSearchOnOptions,
      handleSelectAOption,
      setSelectedOption,
   }
}

export default useAsyncSelect
