import * as Yup from "yup"

export const FIELDS_NAME = {
   TRAILER_NO: "trailerNo",
   TRAILER_TYPE: "trailerType",
   STATIONS: "stations",
   COLORS: "colors",
}

export const initialValues = {
   [FIELDS_NAME.TRAILER_NO]: "",
   [FIELDS_NAME.TRAILER_TYPE]: "",
   [FIELDS_NAME.COLORS]: "",
}

export const validationSchema = Yup.object({
   [FIELDS_NAME.TRAILER_TYPE]: Yup.string()
      .required("Enter trailer type  between 2 to 250 characters")
      .min(2, "Enter trailer type  between 2 to 250 characters")
      .max(250, "Enter trailer type between 2 to 250 characters"),

   [FIELDS_NAME.TRAILER_NO]: Yup.string()
      .required("Enter trailer  No. between 1 to 250 characters")
      .min(1, "Enter trailer  No. between 1 to 250 characters")
      .max(250, "Enter trailer No. between 1 to 250 characters"),

   [FIELDS_NAME.COLORS]: Yup.array()
      .min(1, "At least one color must be selected")
      .required("Select color"),
})
