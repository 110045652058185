import { injectBaseConstantMethods } from "./BaseConstants"

const TrailerSize = {
   S15: "S15",
   S17: "S17",
   S22: "S22",
   S24: "S24",
   S28: "S28",
}

const displayTextKeys = {
   [TrailerSize.S15]: "15'",
   [TrailerSize.S17]: "17'",
   [TrailerSize.S22]: "22'",
   [TrailerSize.S24]: "24'",
   [TrailerSize.S28]: "28'",
}

const labelClass = {}

export default injectBaseConstantMethods(
   TrailerSize,
   displayTextKeys,
   labelClass
)
