import React from "react"
import { CardBody, Card } from "reactstrap"

import { DataTable } from "components/DataTable"
import { Pagination } from "components/Pagination"
import { SearchBox } from "components/SearchBox"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { CountsheetItemDetails } from "api/api.service"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const COLUMNS = {
   DATE: "Date",
   NAME: "Name",
   ACTUAL_QUANTITY: "Actual Quantity",
   AUDIT_QUANTITY: "Audit Quantity",
}

const ItemDetails = ({
   auditFor = "",
   itemsId = "",
   warehouseId = "",
   departmentId = "",
   name = "",
   subKey = "",
}) => {
   const {
      page,
      searchText,
      handleSearchChange,
      handlePageClick,
      pageSize,
      onChangePageSize,
      data,
      total,
      isFetching,
   } = useLocalPaginatedRequest({
      requestFn: CountsheetItemDetails,
      params: {
         ...(departmentId?.length && { departmentId: departmentId }),
         ...(warehouseId && { warehouseId: warehouseId }),
         auditCategory: auditFor,
         itemsId: itemsId,
      },
      deps: [],
      dataKey: DATA_KEY,
      totalKey: TOTAL_KEY,
   })

   return (
      <>
         <Card className="mb-5">
            <div className="d-flex justify-content-center">
               <h3>Item# {itemsId}</h3>
            </div>
            <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
               <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness ">
                     <div className="ad-location-search-box">
                        <SearchBox
                           inputClasses="vendor-search-input input-search"
                           labelClass="d-none"
                           placeholder="Search"
                           searchIcon={true}
                           searchIconClass="vendor-table-search-icon"
                           conClass="vendor-table-search-inner-class"
                           searchText={searchText}
                           onChange={handleSearchChange}
                        />
                     </div>
                  </div>
               </div>
               <DataTable
                  data={data}
                  tableClasses="ad-warehouse-general-inventory-table"
                  loading={isFetching}
                  config={[
                     {
                        title: COLUMNS.NAME,
                        render: data => data?.[name]?.[subKey],
                     },
                     {
                        title: COLUMNS.DATE,
                        render: data =>
                           convertDateTime({
                              date: data.updatedAt,
                              customFormat:
                                 dateTimeFormat.casitaCustomDateFormat,
                              dateOnly: true,
                           }),
                     },
                     {
                        title: COLUMNS.ACTUAL_QUANTITY,
                        render: data => data.existingQuantity,
                     },
                     {
                        title: COLUMNS.AUDIT_QUANTITY,
                        render: data => data.auditQuantity,
                     },
                  ]}
               />
               <Pagination
                  currentPage={page}
                  totalData={total}
                  onPageClick={handlePageClick}
                  rowLimit={pageSize}
               />
            </CardBody>
         </Card>
      </>
   )
}

export default ItemDetails
